import classNames from "classnames";
import {MediaImage} from "../templates/media/media--image";
import {ARTICLE_WIDE_IMAGE_STYLE} from "../../types/image-styles";
import {Heading} from "../atoms/heading";
import {DrupalMedia} from "next-drupal";
import {FormattedText} from "../formatted-text";
import {Button} from "../atoms/button";
import {CSSProperties, useEffect, useRef} from "react";

export function WidgetFrostedDetailCard ({image, title, body, subtitle, button, contain, ...props}: {
  image?: DrupalMedia,
  title: string,
  subtitle?: string,
  button?: {
    url: string,
    title: string,
  },
  body?: string,
  contain?: boolean,
}) {
  const cardRef = useRef<HTMLDivElement|null>(null);
  const imageStyle: CSSProperties = {position: "absolute", width: "100%", height: "60%"};
  if (contain) {
    imageStyle.objectFit = "contain";
    imageStyle.objectPosition = "center";
    imageStyle.width = "75%";
    imageStyle.height = "65%";
    imageStyle.left = 0;
    imageStyle.right = 0;
    imageStyle.margin = "auto";
  }

  useEffect(() => {
    if (!cardRef.current) return;

    const parent: HTMLDivElement|null = cardRef.current.closest('.layout-region');
    if (!parent) return;

    const cards = Array.from(parent.querySelectorAll('.frosted-detail-card'));

    const maxHeight = cards.reduce((max, card) => {
      const title:HTMLElement|null = card.querySelector('.text-3xl');
      if (title) {
        return Math.max(max, title.clientHeight);
      }
    }, 0);

    // Apply that height to the titles of all sibling cards
    cards.forEach(card => {
      const title:HTMLElement|null = card.querySelector('.text-3xl');
      if (title) {
        title.style.minHeight = `${maxHeight}px`;
      }
    });
  }, []);

  return (
    <div className={classNames("block h-full")} ref={cardRef}>
      <div
        className="frosted-detail-card flex flex-col overflow-hidden relative space-y-4 bg-white box-shadow-sm border border-gray-200 rounded-md h-full" {...props}>
        {image && (
          <MediaImage
            media={image}
            imageStyle={ARTICLE_WIDE_IMAGE_STYLE}
            className={"max-h-[70vw]"}
            sizes="(min-width: 968px) 1200px, (min-width: 768px) 50w, 100vw"
            style={imageStyle}
            childClass="transition-all duration-1000 max-h-[70vw] lg:max-h-[70vw] object-cover object-top"
          ></MediaImage>
        )}
        <div className="flex flex-col text-black z-10 h-full justify-end">
          <div className={classNames("mt-64 bg-hygienaLight/60 backdrop-blur-md px-8 flex flex-col py-6 flex-grow")}>
            <div>
              {subtitle && (
                <div className="text-primary font-bold text-lg">{subtitle}</div>
              )}
              <Heading headerType={4} className="pb-4 text-3xl leading-tight">{title}</Heading>
            </div>
            <div className="grid flex-grow">
              {body && <FormattedText processed={body}/>}
              {button && (
                <div className="pt-4 self-end">
                  <Button
                          className="inline-block"
                          href={button.url}
                          height={15}
                          width={15}
                          position={"right"}
                          hover={{color: "primary"}}>{button.title}</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}